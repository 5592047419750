.loginContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 90vh;
    background-color: rgba(0, 0, 0, 0.9);
}

.loginBox {
    width: 500px;
    padding: 20px;
    background-color: #1a1a1a;
    border-radius: 5px;
    border: 2px solid rgba(0, 212, 255, 0.5);
    box-shadow: 0px 0px 10px rgba(0, 212, 255, 0.2);
}

.title {
    font-size: 24px;
    color: #fff;
    text-align: center;
    margin-bottom: 20px;
}

.inputField {
    padding: 10px;
    border: 2px solid #00d4ff;
    border-radius: 5px;
    background-color: transparent;
    color: white;
    font-family: 'Roboto', sans-serif;
    width: 95%;
    margin-bottom: 15px;
    display: block;
}

.loginButton, .signUpButton {
    background: transparent;
    color: #fff;
    border: none;
    padding: 0.5em 1em;
    font-weight: 500;
    cursor: pointer;
    margin: 10px 5px;
    font-size: 1em;
    border-radius: 25px;
    transition: all 0.3s ease;
    box-shadow: inset 1px 0 0 #00d4ff, inset -1px 0 0 #00d4ff;
    display: inline-block;
}

.loginButton:hover, .signUpButton:hover {
    color: #00d4ff;
    box-shadow: inset 2px 0 0 #fff, inset -2px 0 0 #fff;
}

.buttonContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.forgotPassword {
    font-size: 12px;
    cursor: pointer;
    color: #fff;
}

.logo {
    width: 100px;
    display: block;
    margin: 10px auto;
}

/* Mobile View */
@media screen and (max-width: 768px) {
    .loginBox {
        width: 90%;
        padding: 1em;
        background-color: transparent;
        border: none;
        box-shadow: none;
    }
}