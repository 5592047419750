.preloader-wrap {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    bottom: 0;
    background: #000;
    z-index: 1800;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
}

.outer {
    box-sizing: border-box;
}

.inner {
    box-sizing: border-box;
}

.trackbar {
    position: relative;
    padding: 0;
    opacity: 1;
}

.loadbar {
    height: 20px;
    background: #00d4ff;
    animation: loading 1.5s linear forwards;
}


.preloader-intro span {
    font-size: 2em;
    line-height: 2.5em;
    font-weight: 500;
}

@keyframes loading {
    0% { width: 0; }
    100% { width: 100%; }
}
