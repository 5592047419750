.blogContainer {
    background-color: rgba(0, 0, 0, 0.9);
    padding: 70px 0;
    display: flex;
    justify-content: center;
    min-height: 100vh;
}

.contentWrapper {
    width: 60%;
    text-align: center;
    margin: 0 auto;
}

.pageTitle {
    font-size: 36px;
    color: #00d4ff;
    margin-bottom: 20px;
}

.pageSubtitle {
    font-size: 18px;
    color: #aaa;
    max-width: 800px;
    margin: 0 auto;
}


.articlesGrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;
    margin-top: 40px;
}


.articleCard {
    background-color: #1a1a1a;
    border-radius: 10px;
    transition: all 0.3s ease;
    display: flex;
    flex-direction: column;
}

.articleCard:hover {
    background-color: #2a2a2a;
}

.articleImage {
    width: 100%;
    height: 300px;
    object-fit: cover;
    border-radius: 10px 10px 0 0;
    margin-bottom: 0;
}

.textContainer {
    padding: 15px;
    border-radius: 0 0 10px 10px;
    background: linear-gradient(to bottom, rgba(26, 26, 26, 0), rgba(26, 26, 26, 1));
}

.articleTitle,
.articleSummary {
    text-align: left;
    margin-bottom: 10px;
}

.articleTitle {
    font-size: 24px;
    color: #fff;
    font-weight: 600;
}

.articleSummary {
    font-size: 16px;
    color: #aaa;
}

.readMoreLink {
    color: #00d4ff;
    text-decoration: none;
    font-weight: 600;
    transition: all 0.3s ease;
}

.readMoreLink:hover {
    text-decoration: underline;
    color: #fff;
}

/* Tablet View */
@media screen and (max-width: 1024px) {
    .contentWrapper {
        width: 80%;
    }

    .articlesGrid {
        grid-template-columns: repeat(2, 1fr);
    }
}

/* Mobile View */
@media screen and (max-width: 768px) {
    .contentWrapper {
        width: 95%;
    }

    .articlesGrid {
        grid-template-columns: repeat(1, 1fr);
    }

    .articleTitle {
        font-size: 20px;
    }

    .articleSummary {
        font-size: 14px;
    }

    .articleImage {
        height: 200px;
    }
}