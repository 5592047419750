.header {
    position: fixed;
    width: 100%;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    z-index: 1000;
    color: white;
    font-family: 'Roboto', sans-serif;
    text-transform: uppercase;
    letter-spacing: 2px;
    background-color: transparent;
    border-bottom: 1px solid transparent;
    transition: background-color 0.3s ease, border-bottom 0.3s ease;
}

.header.scrolled {
    background-color: rgba(0, 0, 0, 0.9);
    border-bottom: 1px solid rgba(0, 212, 255, 0.9);
}

.menuContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.logo {
    padding: 5px 25px 5px 25px;
    height: 70px;
    transition: filter 0.3s ease;
    opacity: 0;
    transform: scale(0);
}

.logo:hover {
    filter: brightness(0.5) contrast(1.5);
}

.logoWrapper {
    width: 240px;
}

.nav, .shopControls {
    padding-right: 25px;
}

.nav {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0 auto;
    opacity: 0;
    transform: translateY(0px);
}

.nav li {
    margin-right: 20px;
}

.nav a {
    color: #fff;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 0.15em;
    display: inline-block;
    padding: 10px 10px;
    position: relative;
    font-size: 20px;
    font-weight: 600;
}

.nav a:after {
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: "";
    display: block;
    height: 2px;
    left: 50%;
    position: absolute;
    background: #fff;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
}

.nav a:hover:after {
    width: 100%;
    left: 0;
}

.nav a.active {
    color: #00d4ff;
}

.shopControls {
    display: flex;
    align-items: center;
    opacity: 0;
    right: 25px;
    transform: translateY(-100px);
}

.shopControls a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: white;
    font-size: 20px;
    font-weight: 600;
    transition: color 0.3s ease, transform 0.3s ease;
    margin-right: 10px;
}

.shopControls a:hover {
    color: #00d4ff;
}

.shopIcon {
    margin-right: 5px;
}

.shopText {
    font-size: 16px;
}

.hamburger {
    display: none;
    flex-direction: column;
    justify-content: space-between;
    height: 20px;
    width: 25px;
    cursor: pointer;
    padding: 25px;
}

.hamburger span {
    background-color: #fff;
    height: 3px;
    width: 100%;
}

.navContainer {
    display: none;
    flex-direction: column;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.9);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999;
}

.navContainer.open {
    display: flex;
}

@media screen and (max-width: 1024px) {
    .nav, .shopControls, .logo {
        display: none;
    }

    .header.scrolled {
        background-color: transparent;
        border-bottom: transparent;
    }

    .menuContainer.open {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.9);
        z-index: 999;
    }

    .menuContainer.open .nav {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-top: 20px;
    }

    .menuContainer.open .shopControls {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        width: 100%;
        margin-top: 50px;
    }


    .hamburger {
        display: flex;
        z-index: 1000;
    }

    .nav li, .shopControls a {
        margin-right: 0;
        margin-bottom: 10px;
        text-align: center;
        width: 100%;
    }

    .nav li a, .shopControls a {
        padding: 15px;
        display: block;
        color: #fff;
    }

    .shopIcon {
        margin-right: 5px;
        font-size: 24px;
    }

    .shopText {
        font-size: 16px;
        display: inline-block;
    }

}




