.testimonials {
    background: #000;
    color: #fff;
    padding: 2em;
    text-align: center;
    margin-top: 10em;
}

.container {
    max-width: 60%;
    margin: 0 auto;
}

.title {
    font-size: 2em;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    letter-spacing: 0.1em;
    margin-bottom: 2em;
    position: relative;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    text-transform: uppercase;
}

.title::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: -5px;
    height: 1px;
    background: radial-gradient(circle at center, #00d4ff 10%, transparent 100%);
}




.testimonialGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 3em;
}

.testimonialCard {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #1b1b1b;
    border-radius: 10px;
    box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3);
    padding: 2em;
    color: #fff;
    transition: all 0.3s ease-in-out;
    position: relative;
}

.testimonialCard:hover {
    transform: scale(1.05);
}

.quote {
    font-size: 1.2em;
    color: #aaa;
    margin-bottom: 1em;
    line-height: 1.6;
    position: relative;
}

.quote::before {
    content: '“';
    position: absolute;
    font-size: 3em;
    color: #00d4ff;
    top: -10px;
    left: -20px;
}

.author {
    font-size: 1.2em;
    color: #00d4ff;
    font-weight: bold;
}

.stars {
    margin-bottom: 1em;
}

.quoteBox {
    flex-grow: 1;
}

.ratingSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 2em;
    color: #fff;
    background: rgba(0, 0, 0, 0.7);
    padding: 2em;
    border-radius: 10px;
    box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3);
}

.seeAllButton {
    background: transparent;
    color: #fff;
    border: none;
    padding: 0.5em 1em;
    cursor: pointer;
    margin-top: 1em;
    font-size: 1.5em;
    border-radius: 25px;
    transition: all 0.3s ease;
    box-shadow: inset 2px 0 0 #00d4ff, inset -2px 0 0 #00d4ff;
}

.seeAllButton:hover {
    color: #00d4ff;
    box-shadow: inset 2px 0 0 #fff, inset -2px 0 0 #fff;
}

/* For tablets (768px and up) */
@media screen and (max-width: 1024px) {
    .testimonials .container {
        max-width: 80%;
    }

    .testimonials .title {
        font-size: 1.5em;
    }

    .testimonialCard {
        padding: 1.5em;
    }

    .quote {
        font-size: 1em;
    }

    .author {
        font-size: 1em;
    }

    .seeAllButton {
        font-size: 1.2em;
    }
}

/* For mobile devices (767px and below) */
@media screen and (max-width: 767px) {
    .testimonials .container {
        max-width: 100%;
    }

    .testimonials .title {
        font-size: 1.2em;
    }

    .testimonialGrid {
        grid-template-columns: 1fr;
    }

    .testimonialCard {
        padding: 1em;
    }

    .quote {
        font-size: 0.9em;
    }

    .author {
        font-size: 0.9em;
    }

    .seeAllButton {
        font-size: 1em;
    }
}
