.footerWrapper {
    border-top: 2px solid #00F5FF;
}

.footer {
    background: #000;
    color: #fff;
    padding: 0;
    width: 60%;
    margin: 0 auto;
    font-size: 1em;
}

.contactSection {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    border-bottom: 1px solid #00F5FF;
    padding-bottom: 20px;
}

.contactItem {
    display: flex;
    align-items: center;
}

.icon {
    font-size: 40px;
    color: #00d4ff;
    margin-right: 15px;
}

.contactText {
    margin-top: -25px;
    display: inline-block;
}

.contactText h4 {
    color: #fff;
    font-size: 1.2em;
    margin-bottom: 5px;
    line-height: 1;
}

.contactText span {
    color: #fff;
    line-height: 1;
}

.container {
    display: flex;
    justify-content: space-between;
}

.leftSection {
    text-align: left;
    flex: 1;
}

.companyName {
    font-size: 1.6em;
    color: #fff;
    margin-bottom: 10px;
    line-height: 1;
}

.slogan {
    color: #fff;
    font-size: 0.96em;
    border-top: 1px solid #00d4ff;
    padding-top: 5px;
}

.middleSection {
    text-align: center;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5px;
}

.socialIcons {
    display: flex;
    justify-content: space-around;
    font-size: 56px;
    color: #00d4ff;
    width: 350px;
    padding: 20px;
}

.socialIcons a {
    color: #00d4ff;
    transition: color 0.3s ease;
}

.socialIcons a:hover {
    color: #fff;
}

.rightSection {
    text-align: center;
    flex: 1;
}

.rightSection ul {
    list-style: none;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 0;
}

.rightSection ul:last-child {
    border-top: 1px solid #00d4ff;
    padding-top: 10px;
}

.rightSection ul li {
    margin-bottom: 5px;
    flex: 1;
    text-align: center;
}

.rightSection ul li a {
    color: #fff;
    text-decoration: none;
    font-size: 0.96em;
    padding: 5px 10px;
    display: block;
}

.rightSection ul li a:hover {
    color: #00d4ff;
    background-color: #000;
}


.copyright {
    border-top: 2px solid #00F5FF;
    text-align: center;
    color: #aaa;
}

/* For tablets (768px and up) */
@media screen and (max-width: 1024px) {
    .footer {
        width: 95%;
    }

    .icon {
        font-size: 30px;
    }

    .socialIcons {
        font-size: 40px;
        width: 250px;
        margin-top: 20px;
    }

    .contactText h4, .contactText span, .slogan, .rightSection ul li a {
        font-size: 0.8em;
    }

    .companyName{
        font-size: 1.25em;
        margin-top: 35px;
    }

}

/* For mobile devices (767px and below) */
@media screen and (max-width: 767px) {
    .footer {
        width: 100%;
        padding: 0;
        font-size: 0.9em;
    }

    .contactSection {
        flex-direction: column;
        align-items: center;
    }

    .contactItem {
        flex-direction: column;
        align-items: center;
        margin-bottom: 15px;
    }

    .icon {
        margin-right: 0;
        margin-bottom: 10px;
    }

    .contactText {
        margin-top: 0;
        text-align: center;
        margin-right: 5px;
    }

    .container {
        flex-direction: column;
        align-items: center;
    }

    .leftSection, .middleSection, .rightSection {
        text-align: center;
        margin-bottom: 20px;
    }

    .socialIcons {
        font-size: 40px;
        width: 250px;
        padding: 10px;
    }

    .rightSection ul {
        flex-direction: column;
    }

    .rightSection ul li {
        margin-bottom: 10px;
    }

    .copyright {
        font-size: 15px;
    }
}

