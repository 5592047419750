.contactSection {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #000;
    color: #fff;
    padding: 1em;
    height: 80vh;
    position: relative;
    overflow: hidden;
}

.splitScreen {
    display: flex;
    width: 100%;
    height: 100%;
    position: relative;
}

.splitScreen::before {
    content: '';
    position: absolute;
    top: 25%;
    bottom: 25%;
    left: 50%;
    border-left: 2px solid #00d4ff;
    box-shadow: 0 0 10px #00d4ff, 0 0 5px #00d4ff;
    z-index: 1;
}


.newsletter, .contactForm {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1em;
    position: relative;
    background: #000;
    text-align: center;
}

.newsletter h2, .contactForm h2 {
    font-size: 2.5em;
    text-transform: uppercase;
    color: #00d4ff;
    text-align: center;
    margin-bottom: 0.5em;
}

.newsletter p, .contactForm p {
    font-size: 1.2em;
    color: #fff;
    text-align: center;
    margin-top: 0.5em;
    margin-bottom: 1em;
}

.inputWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70%;
    margin: 0 auto;
    position: relative;
}

.newsletter input {
    flex: 1;
    width: 400px;
    border: none;
    background: transparent;
    color: #fff;
    outline: none;
    padding: 0.5em 0;
    position: relative;
    margin-bottom: 10px;
    text-align: left;
    font-weight: bold;
}


.emailWrapper {
    position: relative;
    width: 100%;
}

.emailWrapper::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: -5px;
    height: 1px;
    background: radial-gradient(circle at center, #00d4ff 30%, transparent 100%);
    width: 100%;
    margin-bottom: 10px;
}


.newsletter button, .contactForm button {
    background: transparent;
    color: #fff;
    border: none;
    padding: 0.5em 1em;
    cursor: pointer;
    margin-top: 1em;
    font-size: 1em;
    border-radius: 25px;
    transition: all 0.3s ease;
    box-shadow: inset 2px 0 0 #00d4ff, inset -2px 0 0 #00d4ff;
}

.newsletter button:hover, .contactForm button:hover {
    color: #00d4ff;
    box-shadow: inset 2px 0 0 #fff, inset -2px 0 0 #fff;
}


.contactForm input:focus, .contactForm textarea:focus {
    border: 2px solid #fff;;
    box-shadow: 0 0 10px #fff;
}


.contactForm input, .contactForm textarea {
    width: 100%;
    padding: 1em;
    border: 2px solid #00d4ff;
    box-shadow: 0 0 10px #00d4ff;
    margin-bottom: 1em;
    border-radius: 25px;
    background: #000;
    color: #fff;
    font-weight: bold;
    outline: none;
}

.contactForm textarea {
    height: 200px;
}

.qrCode {
    width: 300px;
    height: auto;
    margin-bottom: 1em;
}

.confirmationMessage {
    font-size: 1em;
    color: #00d4ff;
    margin-top: 1em;
    text-align: center;
    font-weight: 600;
    transition: all 0.3s ease;
}

/* For tablets (768px and up) */
@media screen and (max-width: 1024px) {
    .contactSection .splitScreen {
        flex-direction: column;
    }

    .contactSection {
        height: auto;
        min-height: 80vh;
        padding-bottom: 2em;
        overflow: hidden;
    }

    .contactSection .splitScreen::before {
        display: none;
    }

    .contactSection .inputWrapper {
        width: 90%;
    }

    .contactSection .newsletter input,
    .contactSection .contactForm input,
    .contactSection .contactForm textarea {
        width: 100%;
    }

    .contactSection .contactForm textarea {
        width: 100%;
        height: 150px;
    }

    .contactSection .qrCode {
        width: 200px;
    }
}

/* For mobile devices (767px and below) */
@media screen and (max-width: 767px) {
    .contactSection {
        padding: 0;
        height: auto;
    }

    .contactSection .newsletter h2,
    .contactSection .contactForm h2 {
        font-size: 2em;
    }

    .contactSection .newsletter p,
    .contactSection .contactForm p {
        font-size: 1em;
    }

    .contactSection .newsletter button,
    .contactSection .contactForm button {
        font-size: 0.9em;
        margin-bottom: 2em;
    }

    .contactSection .contactForm textarea {
        height: 150px;
    }
    .contactForm input, .contactForm textarea{
        padding: 1em 5px;
    }

}
