.contactUsSection {
    display: flex;
    background-color: #000;
    color: #fff;
    height: calc(100vh - 60px);
    position: relative;
    overflow: hidden;
    padding-top: 60px;
    justify-content: center;
}

.leftSide,
.rightSide {
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2em;
    background-color: rgba(0, 0, 0, 0.9);
}

.rightSide {
    position: relative;
    height: 60%;
    margin-top: 10%;
}

.leftSide{
    margin-top: 50px;
}

.contactInfo {
    font-size: 1.5em;
    font-weight: 400;
    color: #fff;
    text-align: center;
    margin-bottom: 2em;
}

.openSchedule {
    margin: 10px 0;
    padding-top: 50px;
    font-size: 20px;
}

.openSchedule h3 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
    color:#00d4ff;
}

.scheduleItem {
    display: flex;
    justify-content: space-between;
}

.contactText {
    font-size: 18px;
    color: #ffffff;
    margin-top: 15px;
}

.googleMap {
    border: 0;
    width: 100%;
    height: 100%;
}

.logo {
    width: 120px;
    display: block;
    margin: 10px auto;
}

.socialIcons {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.socialIcons a {
    color: #00d4ff;
    font-size: 50px;
    margin-right: 15px;
    margin-top: 25px;
    transition: 0.3s;
}

.socialIcons a:hover {
    color: #ffffff;
}

/* Tablet View */
@media screen and (max-width: 1024px) {
    .contactUsSection {
        flex-direction: column;
    }

    .leftSide,
    .rightSide {
        width: 80%;
        margin: 20px auto;
    }

    .rightSide {
        height: 40%;
    }

    .contactInfo,
    .openSchedule {
        font-size: 1.2em;
    }

    .socialIcons a {
        font-size: 40px;
    }
}

/* Mobile View */
@media screen and (max-width: 768px) {
    .contactUsSection {
        flex-direction: column;
        padding-bottom: 160px;
    }
    .logo{
        padding-top: 80px;
    }

    .leftSide,
    .rightSide {
        width: 95%;
        padding: 1em;
        height: auto;
    }

    .rightSide {
        margin-bottom: 20px;
    }

    .googleMap {
        height: 300px;
    }

    .contactInfo,
    .openSchedule {
        font-size: 1em;
    }

    .logo {
        width: 80px;
    }

    .socialIcons a {
        font-size: 30px;
    }

    .scheduleItem {
        flex-direction: column;
        align-items: center;
        gap: 5px;
        text-align: center;
    }
}


