.cartContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 90vh;
    background-color: rgba(0, 0, 0, 0.9);
}

.cartBox {
    width: 500px;
    padding: 30px;
    background-color: #1a1a1a;
    border-radius: 10px;
    border: 2px solid rgba(0, 212, 255, 0.5);
    box-shadow: 0px 0px 10px rgba(0, 212, 255, 0.2);
}

.emptyCartImage {
    width: 175px;
    display: block;
    margin: 20px auto;
    transition: all 0.3s ease;
}

.title {
    font-size: 26px;
    color: #fff;
    text-align: center;
    margin-bottom: 15px;
}

.suggestion {
    font-size: 18px;
    color: #00d4ff;
    text-align: center;
    margin-bottom: 30px;
}

.continueShoppingButton {
    background: transparent;
    color: #fff;
    border: none;
    padding: 0.5em 1em;
    font-weight: 600;
    cursor: pointer;
    margin: 10px 5px;
    font-size: 1.5em;
    border-radius: 25px;
    transition: all 0.3s ease;
    box-shadow: inset 2px 0 0 #00d4ff, inset -2px 0 0 #00d4ff;
    display: block;
    margin: 0 auto;
}

.continueShoppingButton:hover {
    color: #00d4ff;
    box-shadow: inset 2px 0 0 #fff, inset -2px 0 0 #fff;
}

/* Tablet View */
@media screen and (max-width: 1024px) {
    .cartBox {
        width: 70%;
    }

    .title,
    .suggestion {
        font-size: 1.2em;
    }

    .continueShoppingButton {
        font-size: 1.2em;
    }
}

/* Mobile View */
@media screen and (max-width: 768px) {
    .cartBox {
        width: 90%;
        padding: 1em;
        background-color: transparent;
        border: none;
        box-shadow: none;
    }

    .title,
    .suggestion {
        font-size: 1em;
    }

    .continueShoppingButton {
        font-size: 1em;
    }

    .emptyCartImage {
        width: 150px;
    }
}
