/* Products Container */
.productsContainer {
    display: flex;
    padding-top: 120px;
    background: #000;
    color: #fff;
}

/* Filter Section */
.filterSection {
    width: 20%;
    padding: 20px;
    border-right: 1px solid #00d4ff;
    background: #111;
}
.filterTitle {
    font-weight: bold;
    margin-bottom: 10px;
    color: #00d4ff;
}
.filterOption {
    margin: 5px 0;
}
.filterOption select,
.filterOption input {
    width: 100%;
    padding: 5px;
    margin-top: 5px;
    border: 1px solid #00d4ff;
    border-radius: 4px;
    background: #222;
    color: #fff;
}

/* Products Section */
.productsSection {
    width: 90%;
    padding: 20px 150px 20px 20px;
}
.searchField {
    width: 100%;
    padding: 10px;
    margin-bottom: 35px;
    border: 1px solid #00d4ff;
    background: #222;
    color: #fff;
}
.productsGrid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 30px;
}

/* Product Card */
.productCard {
    text-align: center;
    background: #111;
    color: #fff;
    padding: 2em 2em 3em 2em;
    position: relative;
}
.productCard::before,
.productCard::after {
    content: '';
    position: absolute;
    border-style: solid;
    border-color: #00d4ff;
    transition: all 0.3s ease;
}
.productCard::before {
    top: 0;
    left: 0;
    border-width: 2px 0 0 2px;
    width: 20px;
    height: 20px;
}
.productCard::after {
    bottom: 0;
    right: 0;
    border-width: 0 2px 2px 0;
    width: 20px;
    height: 20px;
}
.productCard:hover::before {
    width: 100%;
}
.productCard:hover::after {
    width: 100%;
}
.productImage {
    width: 100%;
    height: 60%;
    object-fit: contain;
}
.productTitle {
    font-size: 1.5em;
    margin: 0.5em 0;
}
.productPrice {
    font-size: 1.5em;
    color: #00d4ff;
    margin: 0.5em 0;
}
.productRating {
    font-size: 1em;
    color: #aaa;
}
.productStock {
    font-size: 1em;
    color: #00d4ff;
}
.readMoreButton {
    background: transparent;
    color: #00d4ff;
    border: none;
    cursor: pointer;
    text-decoration: underline;
    transition: all 0.3s ease;
    font-size: 1em;
}
.readMoreButton:hover {
    color: #fff;
}

/* Overlay and Modal */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
}
.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 80%;
    width: auto;
    max-height: 80%;
    height: auto;
    background: #111;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    border: 2px solid #00d4ff;
    overflow: auto;
    padding: 20px;
    box-sizing: border-box;
}
.modal img {
    width: 50%;
    height: auto;
    margin-bottom: 20px;
}
.modal h3 {
    font-size: 1.5em;
    color: #fff;
    margin-bottom: 20px;
}
.modal p {
    font-size: 1em;
    color: #aaa;
    max-width: 80%;
    text-align: center;
}
.closeButton {
    position: absolute;
    top: 20px;
    right: 20px;
    background: transparent;
    color: #fff;
    border: none;
    font-size: 1.5em;
    cursor: pointer;
}
.closeButton:hover {
    color: #00d4ff;
}

/* Other Styles */
.noScroll {
    overflow: hidden;
}
.buttonContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.priceRangeContainer {
    display: flex;
    flex-direction: column;
}
.priceValues {
    display: flex;
    justify-content: space-between;
    color: #00d4ff;
}
.sliderContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 25px;
}
.priceLabels {
    display: flex;
    justify-content: space-between;
    width: 100%;
    color: #00d4ff;
    margin-top: 10px;
}
.minPriceLabel {
    text-align: left;
}
.maxPriceLabel {
    text-align: right;
}
.sortContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 15px;
}
.sortContainer label {
    margin-right: 10px;
    color: #00d4ff;
}
.sortContainer select {
    padding: 5px;
    border: 1px solid #00d4ff;
    border-radius: 4px;
    background: #222;
    color: #fff;
}

/* Media Queries */
@media screen and (min-width: 768px) and (max-width: 1024px) {
    .filterSection {
        width: 30%;
    }
    .productsSection {
        width: 70%;
        padding: 20px;
    }
    .productsGrid {
        grid-template-columns: repeat(1, 1fr);
    }
    .productCard {
        padding: 1em 1em 2em 1em;
    }
    .modal {
        max-width: 90%;
    }
}
@media screen and (max-width: 768px) {
    .filterSection {
        width: 100%;
        padding: 10px;
        box-sizing: border-box;
    }
    .filterOption {
        margin: 10px 0;
    }
    .filterOption select,
    .filterOption input {
        padding: 10px;
        box-sizing: border-box;
    }
    .searchField {
        width: 90%;
        margin: 10px auto;
        padding: 10px;
        box-sizing: border-box;
    }
    .productsContainer {
        flex-direction: column;
        padding-top: 60px;
    }
    .productsSection {
        width: 100%;
        padding: 10px;
        box-sizing: border-box;
    }
    .productsGrid {
        grid-template-columns: repeat(1, 1fr);
    }
    .productCard {
        padding: 10px;
        font-size: 0.9em;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .productImage {
        width: 70%;
        height: auto;
        margin-bottom: 10px;
    }
    .productTitle,
    .productPrice,
    .productRating,
    .productStock {
        margin: 5px 0;
        text-align: center;
    }
    .buttonContainer {
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }
    .readMoreButton {
        margin-top: 10px;
    }
    .modal {
        max-width: 95%;
    }
}
