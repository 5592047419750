.aboutSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100vh;
    background: #000;
    color: #fff;
    padding: 2em;
    position: relative;
    overflow: hidden;
}

@keyframes moveUpDown {
    0% { top: 25%; }
    50% { top: 95%; }
    100% { top: 25%; }
}

.glowLine {
    position: absolute;
    width: 100%;
    height: 2px;
    background: #00d4ff;
    top: 50%;
    left: 0;
    box-shadow: 0 0 25px 15px #00d4ff;
    animation: moveUpDown 15s linear infinite;
}

.content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
}

.textBox {
    background: rgba(0, 0, 0, 0.7);
    color: #fff;
    padding: 1em;
    width: 45%;
    z-index: 1;
    box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    font-size: 1.5em;
    line-height: 1.6;
}

.textBox h2 {
    margin-bottom: 1em;
    color: #00d4ff;
    font-size: 2em;
}

.imageBox {
    width: 50%;
    position: relative;
    left: -10%;
}

.imageBox img {
    width: 100%;
    height: auto;
    box-shadow: 0 0 10px 5px #00d4ff;
}

.highlight {
    color: #00d4ff;
}
.placeholder {
    height: 0.5em;
}


/* For tablets (768px and up) */
@media screen and (max-width: 1024px) {
    .aboutSection .content {
        flex-direction: column;
        align-items: center;
    }

    .aboutSection .textBox {
        width: 80%;
        font-size: 1.2em;
        text-align: center;
    }

    .aboutSection .imageBox {
        width: 80%;
        left: 0;
    }
}

/* For mobile devices (767px and below) */
@media screen and (max-width: 767px) {
    .aboutSection .textBox {
        width: 100%;
        font-size: 1em;
        padding: 0.8em;
    }

    .aboutSection .imageBox {
        width: 100%;
    }

    .aboutSection .textBox h2 {
        font-size: 1.5em;
    }
}
