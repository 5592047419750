/* FeaturedProducts.module.css */
.featuredProducts {
    padding: 0.1em;
    background: #000;
    color: #fff;
}

.title {
    text-align: center;
    font-size: 2em;
    font-weight: 600;
    color: #fff;
    margin-bottom: 1em;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    letter-spacing: 0.1em;
    position: relative;
    text-transform: uppercase;
}

.title span {
    position: relative;
}

.title span::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: -5px;
    height: 1px;
    background: radial-gradient(circle at center, #00d4ff 10%, transparent 100%);
    width: 100%;
    margin: auto;
}


.grid {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
}

.card {
    position: relative;
    text-align: center;
    background: transparent;
    color: #fff;
    margin: 1em;
    width: 450px;
    height: 600px;
    padding: 2em 2em 3em 2em;
}


.image {
    width: 100%;
    height: 60%;
    object-fit: cover;
    box-shadow: 0 0 10px 1px #fff;
}

.productTitle {
    font-size: 1.5em;
    margin: 0.5em 0;
}

.description {
    font-size: 1.2em;
    color: #aaa;
}

.button {
    background: transparent;
    color: #fff;
    border: none;
    padding: 0.5em 1em;
    cursor: pointer;
    margin-top: 1em;
    font-size: 1.2em;
    border-radius: 25px;
    transition: all 0.3s ease;
    box-shadow: inset 2px 0 0 #00d4ff, inset -2px 0 0 #00d4ff;
}

.button:hover {
    color: #00d4ff;
    box-shadow: inset 2px 0 0 #fff, inset -2px 0 0 #fff;
}




.card::before, .card::after {
    content: '';
    position: absolute;
    border-style: solid;
    border-color: #00d4ff;
}

.card::before {
    top: 0;
    left: 0;
    border-width: 2px 0 0 2px;
    width: 20px;
    height: 20px;
}

.card::after {
    bottom: 0;
    right: 0;
    border-width: 0 2px 2px 0;
    width: 20px;
    height: 20px;
}

.price {
    font-size: 1.5em;
    color: #00d4ff;
    margin: 0.5em 0;
}

.quantity {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1em;
    margin-bottom: 1em;
}

.quantity button {
    background: #333;
    color: #fff;
    border: none;
    padding: 0.5em;
    cursor: pointer;
    border-radius: 50%;
    font-size: 1em;
    width: 35px;
    height: 35px;
}

.quantity span {
    font-size: 1.2em;
    color: #fff;
}

.cta {
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(45deg, #000, #333);
    height: 250px;
    margin: 7em 0 0 0;
    position: relative;
    width: 100%;
}

.ctaContent {
    text-align: center;
    color: #fff;
    z-index: 1;
    font-family: 'Roboto', sans-serif;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.cta h2 {
    font-size: 2em;
    margin-bottom: 0.5em;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.cta p {
    font-size: 1em;
    margin-bottom: 2em;
}

.ctaButton {
    display: block;
    width: 200px;
    height: 50px;
    margin: 0 auto;
    position: relative;
    background: rgba(255, 255, 255, .27);
    text-transform: uppercase;
    color: white;
    font-weight: 700;
    letter-spacing: 1px;
    font-size: 15px;
    outline: none;
    cursor: pointer;
    text-align: center;
    line-height: 50px;
    text-decoration: none;
    overflow: hidden;
    border: 1px solid rgba(255, 255, 255, 0);
    transition: all .2s ease-in-out;
}

.ctaButton:before {
    content: " ";
    display: block;
    width: 200px;
    height: 50px;
    background: rgba(255, 255, 255, .4);
    position: absolute;
    top: -10px;
    left: -200px;
    transform: rotate(-45deg);
    transition: all .2s ease-in-out;
}

.ctaButton:hover:before {
    margin-left: 300%;
}

.ctaButton:hover {
    border: 1px solid rgba(255, 255, 255, 1);
}

.cta::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('../../assets/img/cta-image.webp') no-repeat center center/cover;
    opacity: 0.5;
    z-index: 0;
}

/* For mobile devices (767px and below) */
@media screen and (max-width: 767px) {
    .featuredProducts .card {
        width: 300px;
        height: 450px;
    }

    .featuredProducts .image {
        height: 50%;
    }

    .featuredProducts .productTitle,
    .featuredProducts .description,
    .featuredProducts .price,
    .featuredProducts .quantity span {
        font-size: 1em;
    }

    .featuredProducts .button,
    .featuredProducts .ctaButton {
        font-size: 1em;
    }

    .featuredProducts .quantity button {
        width: 30px;
        height: 30px;
    }
}
