/* Hero.module.css */
html, body {
    margin: 0;
    padding: 0;
}

.hero {
    height: 100vh;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;
    font-family: 'Roboto', sans-serif;
    text-transform: uppercase;
    letter-spacing: 2px;
    overflow: hidden;
    padding: 0 2em;
    background: black;
}

.heroText {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 13em;
}

.heroImage {
    width: 50%;
    height: 100%;
    background-size: cover;
    position: relative;
    background-color: black;
    opacity: 0;
}

.heroTitle, .heroSubtitle {
    opacity: 0;
    font-size: 6em;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    margin: 0;
    z-index: 1;
    letter-spacing: 0.1em;
}

.heroSubtitle {
    font-size: 3em;
}

.keyword {
    color: #00d4ff;
}


.heroImage::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(circle at center, transparent, rgba(0, 0, 0, 1) 70%);
}

.buttons {
    display: flex;
    justify-content: center;
    gap: 1em;
    margin-top: 4em;
}

.button {
    opacity: 0;
    color: #fff;
    border: 2px solid transparent;
    padding: 1em 3em;
    text-decoration: none;
    transition: all 0.3s ease;
    border-radius: 25px;
    font-size: 1.2em;
    width: auto;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 200px;
}

.button:first-child {
    box-shadow: inset -1px 0 0 #00d4ff, inset 1px 0 0 #00d4ff;
    color:#fff;
}

.button:last-child {
    box-shadow: inset -1px 0 0 #fff, inset 1px 0 0 #fff;
    color:#00d4ff;
}

.button:first-child:hover {
    box-shadow: 0 0 10px 2px #00d4ff;
    border-color: #00d4ff;
}

.button:last-child:hover {
    box-shadow: 0 0 10px 2px #fff;
    border-color: #fff;
}

/* For tablets (768px and up) */
@media screen and (max-width: 1024px) {
    .hero {
        flex-direction: column-reverse;
        padding: 1em;
    }

    .heroText {
        padding-left: 2em;
    }

    .heroImage {
        width: 80%;
        height: auto;
        min-height: 50%;
    }

    .heroTitle, .heroSubtitle {
        font-size: 4em;
    }

    .heroSubtitle {
        font-size: 2em;
    }

    .buttons {
        flex-direction: column;
        align-items: center;
        gap: 0.5em;
    }

    .button {
        padding: 0.8em 2em;
        min-width: 150px;
    }
}

/* For mobile devices (767px and below) */
@media screen and (max-width: 767px) {
    .heroText {
        padding-left: 1em;
    }

    .heroImage {
        width: 100%;
        height: auto;
        min-height: 50%;
    }

    .heroTitle, .heroSubtitle {
        font-size: 2em;
    }

    .heroSubtitle {
        font-size: 1em;
    }

    .button {
        padding: 0.6em 1.5em;
        min-width: 120px;
        font-size: 1em;
    }
}
