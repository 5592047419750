.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 80vh;
    background: #000;
    color: #fff;
}

.errorCode {
    font-size: 10em;
    color: #00d4ff;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.errorMessage {
    font-size: 1.5em;
    text-align: center;
    max-width: 80%;
}

@keyframes pulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.05); }
    100% { transform: scale(1); }
}

.errorCode {
    animation: pulse 2s infinite ease-in-out;
}
